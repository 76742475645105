module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ps-snacks","short_name":"ps","start_url":"/","background_color":"#ca2639","theme_color":"#ca2639","display":"minimal-ui","icon":"src/images/logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-117509249-1"],"gtagConfig":{"optimize_id":"GTM-MT3M69C","anonymize_ip":true,"cookie_expires":0,"linker":{"domains":["ps-snacks.com","try.ps-snacks.com"]}},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"313178982494924"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Nickainley Normal","Gotham","Menoe Grotesque Pro","Campton-Medium","CodeSaver-Regular","garagegothic"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
